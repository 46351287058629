import Image from "next/image";
import styles from "./styles.module.css";
import Container from "../Container";
import Button from "../Button";
import Heading from "../Heading";
import { BadgeNonProfit, BadgeEarth } from "../Icons";
import cn from "classnames";
import MyImage from "../MyImage";
import {getImageProps} from "../../lib/helpers";

const CardOnBackground = ({ title = "", text = "", image, button, wide }) => {
  const {
    imageUrl,
    imageAlt
  } = getImageProps(image)

  return (
    <div className={cn(styles.cardOnBackground)}>
      <Container className={styles.background}>
        <div className={styles.inner}>
          <div className={styles.card}>
            <Heading>{title}</Heading>
            <p className={styles.text}>{text}</p>
            {button.url && (
              <div className={styles.buttonWrapper}>
                <Button type="green" href={button.url}>
                  {button.title}
                </Button>
              </div>
            )}
          </div>
          <MyImage
            src={imageUrl}
            layout="fill"
            objectFit="cover"
            alt={imageAlt}
          />
        </div>
      </Container>
    </div>
  );
};

export default CardOnBackground;
